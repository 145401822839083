
import { namespace } from 'vuex-class'
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as sq from '~/store/modules/sq'

import { mixin as clickaway } from 'vue-clickaway'
import { Carrier } from '~/store/interfaces'

const SQ = namespace(sq.name)

@Component({
  components: {
    LoadingSpinnerHorizontal: () =>
      import('~/components/Icons/LoadingSpinnerHorizontal.vue')
  },
  mixins: [clickaway],
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }
})
export default class AddressSearch extends Vue {
  @Prop({ default: null })
  readonly carrier: Carrier | null

  @Prop({ default: null })
  readonly hideTick: Boolean | false

  @Prop({ default: null })
  readonly showLink: Boolean | false

  @SQ.Getter
  sq

  @SQ.Getter
  addressAutocomplete

  @SQ.Mutation
  setAddress

  @SQ.Mutation
  setAddressString

  @SQ.Action
  fetchAddressSuggestions

  @SQ.Getter
  addressAutocompleteInProgress

  @SQ.Getter
  isBusiness

  @SQ.Mutation
  setIsBusiness

  debounce = (fn, time) => {
    let timeout

    // eslint-disable-next-line
    return function() {
      clearTimeout(timeout)
      timeout = setTimeout(fn, time)
    }
  }

  hideAutocomplete = false

  setAutocompleteHidden() {
    this.hideAutocomplete = true
  }

  get showAutocomplete() {
    return !this.hideAutocomplete && this.address.length
  }

  get showLoader() {
    return this.addressAutocompleteInProgress
  }

  addressSearch = this.debounce(() => {
    this.hideAutocomplete = false
    this.fetchAddressSuggestions(this.carrier)
  }, 300)

  setAddressAndHideAutoComplete(address) {
    this.setAddress(address)
    this.setAutocompleteHidden()
  }

  get address() {
    return this.sq.address.address
  }

  set address(address: string) {
    this.setAddressString(address)
  }
  
  get setBusinessSQ() {
    return this.isBusiness
  }

  set setBusinessSQ(b: boolean) {
    this.setIsBusiness(b)
  }

  async created() {
    this.setAutocompleteHidden()
  }
}
